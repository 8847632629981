<template>
  <div class="animated fadeIn">
    <b-card>
      <div class="card-body">
        <form>
          <h5>
            Barrel details <b-spinner v-if="isLoading" small type="grow" />
          </h5>
          <hr />
          <b-row>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.barrel_number.id"
                :value="data.barrel_number"
                :label="controls.barrel_number?.label"
                :readonly="controls.barrel_number.readonly"
                :mode="mode"
                :required="false"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.product.id"
                :value="data.product"
                :label="controls.product.label"
                :readonly="controls.product.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.alcohol_percentage.id"
                :value="data.alcohol_percentage"
                :label="controls.alcohol_percentage.label"
                :readonly="controls.alcohol_percentage.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.barrel_size.id"
                :value="data.barrel_size"
                :label="controls.barrel_size.label"
                :readonly="controls.barrel_size.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.date_barelled.id"
                :value="data.date_barelled"
                :label="controls.date_barelled.label"
                :readonly="controls.date_barelled.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.barrel_age.id"
                :value="data.barrel_age"
                :label="controls.barrel_age.label"
                :readonly="controls.barrel_age.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.amount_in_barrel.id"
                :value="data.amount_in_barrel"
                :label="controls.amount_in_barrel.label"
                :readonly="controls.amount_in_barrel.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.amount_remaining.id"
                :value="data.amount_remaining"
                :label="controls.amount_remaining.label"
                :readonly="controls.amount_remaining.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3" md="6" sm="6">
              <inline-select
                :id="controls.warehouse.id"
                :value="data.warehouse"
                :label="controls.warehouse.label"
                :readonly="controls.warehouse.readonly"
                :options="controls.warehouse.options"
                :allow-empty="false"
                :multiple="false"
                :mode="$constants.FORM_MODE.EDIT"
                @changed="updateSelect"
                :required="controls.warehouse.required"
              />
            </b-col>

            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.reservation_date.id"
                :value="data.reservation_date"
                :label="controls.reservation_date.label"
                :readonly="controls.reservation_date.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.barrel_status.id"
                :value="data.barrel_status"
                :label="controls.barrel_status.label"
                :readonly="controls.barrel_status.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.modified_date.id"
                :value="data.modified_date"
                :label="controls.modified_date.label"
                :readonly="controls.modified_date.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.selection_status.id"
                :value="data.selection_status"
                :label="controls.selection_status.label"
                :readonly="controls.selection_status.readonly"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>

            <b-col lg="3" sm="6">
              <inline-input
                :id="controls.selected_by.id"
                :value="data.selected_by"
                :label="controls.selected_by.label"
                :readonly="controls.selected_by.readonly"
                :mode="mode"
                :required="false"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-date-picker
                :id="controls.ready_by_date.id"
                :value-single="data.ready_by_date"
                :label="controls.ready_by_date.label"
                :readonly="controls.ready_by_date.readonly"
                :mode="mode"
                @changed="updateDateField"
              />
            </b-col>
            <b-col lg="3" sm="6">
              <inline-select
                :id="controls.sample_request.id"
                :width="25"
                :value="data.sample_request"
                :loading="controls.sample_request.loading"
                :label="controls.sample_request.label"
                :readonly="controls.sample_request.readonly"
                :options="controls.sample_request.options"
                :allow-empty="true"
                :multiple="false"
                :mode="mode"
                :link-mode="true"
                @link-clicked="onSampleRequestLinkClicked"
                @changed="updateSampleRequestSelect"
                :required="false"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <inline-input
                :id="controls.notes.id"
                :value="data.notes"
                :label="controls.notes.label"
                :readonly="controls.notes.readonly"
                :mode="mode"
                :required="false"
                :is-text-area="true"
                @changed="updateField"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <h5>Selection history</h5>
              <b-table
                :fields="selectionTable.columns"
                :items="selectionTable.items"
                bg-variant="ligth"
              />
            </b-col>
          </b-row>
        </form>
        <hr />

        <form-submission-actions
          :mode="mode"
          :loading="{
            save: saveInProgress,
            saveAndView: saveAndViewInProgress
          }"
          :buttons-visibility="{
            previous: $customTable.getPrevKey($route.meta.module, id),
            next: $customTable.getNextKey($route.meta.module, id)
          }"
          @previous-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getPrevKey($route.meta.module, id)
              }
            })
          "
          @next-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getNextKey($route.meta.module, id)
              }
            })
          "
          @save="
            save('tabular').then(response =>
              response ? $router.push({ name: 'Barrels' }) : false
            )
          "
          @save-and-view="
            save('view').then(response =>
              response
                ? $router.push({
                    name: 'Barrel submission',
                    params: { action: 'view', id: response }
                  })
                : false
            )
          "
          @edit="
            $router.push({
              name: 'Barrel submission',
              params: { action: 'edit', id: id }
            })
          "
          @back="$router.push($store.getters['router/previousRoute'])"
          @custom-click="
            name => {
              this[name]();
            }
          "
          :custom-buttons="[
            {
              text: 'Notification',
              icon: 'envelope',
              loading: false,
              visible:
                (profile.data.role === 'H2' ||
                  profile.data.full_name === 'Hoffman Mike') &&
                data.barrel_status === 'Reserved',
              method: 'openBarrelReserveMailModal',
              variant: 'warning'
            },
            {
              text: 'Reserve',
              icon: 'registered',
              loading: reservationInProgress,
              visible:
                (profile.data.role === 'H2' ||
                  profile.data.full_name === 'Hoffman Mike') &&
                data.amount_remaining > 0 &&
                data.barrel_status === 'Full',
              method: 'reserveBarrel',
              variant: 'primary'
            },
            {
              text: 'Unreserve',
              icon: 'registered',
              loading: unreservationInProgress,
              visible:
                (profile.data.role === 'H2' ||
                  profile.data.full_name === 'Hoffman Mike') &&
                data.barrel_status === 'Reserved' &&
                data.selection_status === 'Available',
              method: 'unreserveBarrel',
              variant: 'danger'
            }
          ]"
        />
      </div>
    </b-card>

    <b-modal
      ref="barrel-reserve-modal"
      centered
      hide-footer
      size="md"
      title="Barrel reserve notification"
    >
      <b-container fluid>
        <b-row>
          <b-col lg="6" md="6">
            <inline-input
              id="barrel-reserve-sender"
              :value="barrelReserveMail.sender_name"
              :hide-label="false"
              label="Sender"
              :mode="$constants.FORM_MODE.EDIT"
              :required="true"
              :readonly="true"
            />
          </b-col>
          <b-col lg="6" md="6">
            <inline-input
              ref="barrel-reserve-recipient"
              id="barrel-reserve-recipient"
              :value="barrelReserveMail.recipient_email"
              @changed="onBarrelReserveEmailChanged"
              :hide-label="false"
              label="Recipient"
              :mode="$constants.FORM_MODE.EDIT"
              :required="true"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" md="12">
            <inline-input
              ref="barrel-reserve-message"
              id="barrel-reserve-message"
              :value="barrelReserveMail.message"
              @changed="onBarrelReserveMessageChanged"
              :hide-label="false"
              label="Message"
              :mode="$constants.FORM_MODE.EDIT"
              :required="true"
              :is-text-area="true"
            />
          </b-col>
        </b-row>
      </b-container>
      <hr />
      <div class="form-row d-flex justify-content-end">
        <b-button
          variant="outline-dark"
          class="m-1"
          @click="sendBarrelReserveMail()"
          :disabled="barrelReserveMail.isLoading"
        >
          <b-spinner v-if="barrelReserveMail.isLoading" small type="grow" />
          <font-awesome-icon
            v-if="!barrelReserveMail.isLoading"
            icon="paper-plane"
          />
          Send mail
        </b-button>

        <b-button
          variant="outline-dark"
          class="m-1"
          @click="closeBarrelReserveMailModal()"
        >
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import InlineInput from "@/components/InlineInput";
import InlineSelect from "@/components/InlineSelectMultiple";
import InlineDatePicker from "@/components/InlineDatePicker";
//import InlineDatePicker from "@/components/InlineDatePicker";

import FormSubmissionActions from "@/components/FormSubmissionActions";

import {
  //mapGetters,
  mapState,
  mapActions
} from "vuex";

export default {
  name: "BarrelSubmissionForm",
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    action: {
      type: String,
      default: ""
    }
  },
  components: {
    InlineInput,
    InlineSelect,
    InlineDatePicker,
    FormSubmissionActions
  },
  data: function() {
    return {
      mode: undefined,
      isLoading: false,
      reservationInProgress: false,
      unreservationInProgress: false,
      saveInProgress: false,
      saveAndViewInProgress: false,

      barrelReserveMail: {
        sender_name: "",
        recipient_email: "",
        message: "",
        isLoading: false
      },
      controls: {
        buttons: {
          edit: {
            id: "button:details_edit"
          },
          save: {
            id: "button:details_save"
          }
        },
        barrel_number: {
          id: "input:barrel_number",
          label: "Barrel #",
          changed: false,
          readonly: true
        },
        amount_in_barrel: {
          id: "input:amount_in_barrel",
          label: "Amount in barrel",
          changed: false,
          readonly: true
        },
        amount_remaining: {
          id: "input:amount_remaining",
          label: "Amount remaining",
          changed: false,
          readonly: true
        },
        warehouse: {
          id: "select:warehouse",
          label: "Warehouse Location",
          options: [],
          changed: false,
          readonly: false,
          loading: false,
          required: false
        },

        barrel_size: {
          id: "input:barrel_size",
          label: "Barrel size",
          changed: false,
          readonly: true
        },
        date_barelled: {
          id: "input:date_barelled",
          label: "Date barelled",
          changed: false,
          readonly: true
        },
        barrel_age: {
          id: "input:barrel_age",
          label: "Age",
          changed: false,
          readonly: true
        },
        reservation_date: {
          id: "input:reservation_date",
          label: "Reservation date",
          changed: false,
          readonly: true
        },

        barrel_status: {
          id: "input:barrel_status",
          label: "Barrel status",
          changed: false,
          readonly: true
        },

        selection_status: {
          id: "input:selection_status",
          label: "Selection status",
          changed: false,
          readonly: true
        },

        product: {
          id: "input:product",
          label: "Product",
          changed: false,
          readonly: true
        },

        alcohol_percentage: {
          id: "input:alcohol_percentage",
          label: "Alcohol %",
          changed: false,
          readonly: true
        },
        notes: {
          id: "input:notes",
          label: "Notes",
          changed: false,
          readonly: false
        },
        selected_by: {
          id: "input:selected_by",
          label: "Selected by",
          changed: false,
          readonly: true
        },

        ready_by_date: {
          id: "datepicker:ready_by_date",
          label: "Ready by date",
          changed: false,
          readonly: false
        },

        modified_date: {
          id: "input:modified_date",
          label: "Modified date",
          changed: false,
          readonly: true
        },
        sample_request: {
          id: "select:sample_request",
          label: "Sample request",
          changed: false,
          readonly: false,
          options: []
        }
      },
      data: {
        barrel_number: "",
        amount_in_barrel: "",
        amount_remaining: "",
        warehouse: "",
        barrel_size: "",
        date_barelled: "",
        barrel_age: "",
        reservation_date: "",
        barrel_status: "",
        selection_status: "",
        product: "",
        alcohol_percentage: "",
        notes: "",
        selected_by: "",
        ready_by_date: "",
        modified_date: ""
      },
      selectionTable: {
        columns: [
          {
            key: "barrel_number",
            label: "Barrel #"
          },
          {
            key: "barrel_status",
            label: "Reservation Status"
          },

          {
            key: "selection_status",
            label: "Selection Status"
          },
          {
            key: "changed_by",
            label: "Changed By"
          },
          {
            key: "modified_date",
            label: "Modified Date"
          }
        ],
        items: []
      }
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created: function() {
    this.initialize();
  },
  mounted: function() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    initialize: async function() {
      let self = this;

      this.data.barrel_number = this.id;

      /*
                        if (this.action === "create") {
                            this.mode = this.$constants.FORM_MODE.CREATE;
                            this.data.id = await this.$api.get(`entities/nextid`)
                        }
            */
      if (this.action === "edit") {
        this.mode = this.$constants.FORM_MODE.EDIT;
      }
      if (this.action === "view") {
        this.mode = this.$constants.FORM_MODE.VIEW;
      }

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        self.fetchData(this.id);
        self.fetchSelectionHistory(this.id);
      }

      this.loadDictionaries();
    },
    dropdown(_name) {
      return this.dataTable.dropdowns.find(n => n.name === _name);
    },
    async loadDictionaries() {
      let self = this;
      /*
      this.$api
        .post("sample-requests", {
          delivered: ["No"]
        })
        .then(response => {
          self.controls.sample_request.options = response.map(item => ({
            id: item["ID"],
            label: `${item["Requested By"]} on ${item["Request Date"]} [${item["ID"]}]`,
            requester_name: item["Requester"],
            requester_email: item["Requester Email"]
          }));
        });*/
      const samples = async () => {
        let response = await this.$api.post("sample-requests", {
          delivered: ["No"]
        });

        self.controls.sample_request.options = response.map(item => ({
          id: item["ID"],
          label: `${item["Requested By"]} on ${item["Request Date"]} [${item["ID"]}]`,
          requester_name: item["Requester"],
          requester_email: item["Requester Email"]
        }));
      };

      const warehouses = async () => {
        let response = await this.$api.get("dictionaries/warehouses");

        self.controls.warehouse.options = response.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      await Promise.all([samples(), warehouses()]);
    },
    async fetchData(id) {
      let self = this;

      self.isLoading = true;

      return this.$api
        .get(`barrels/${id}`)
        .then(response => {
          if (self.$_.isEmpty(response)) return;

          self.data.barrel_number = response["Barrel #"];

          self.data.amount_in_barrel = response["Amount"];
          self.data.amount_remaining = response["Amount Remaining"];
          self.data.warehouse = {
            id: response["Warehouse ID"],
            label: response["Warehouse"]
          };
          self.data.barrel_size = response["Size"];
          self.data.date_barelled = response["Date Barreled"];
          self.data.barrel_age = response["Age"];
          self.data.reservation_date = response["Reservation Date"];
          self.data.barrel_status = response["Status"];
          self.data.selection_status = response["Selection Status"];
          self.data.product = response["Product"];
          self.data.alcohol_percentage = response["Alc %"];
          self.data.notes = response["Notes"];
          self.data.selected_by = response["Selected By"];
          self.data.ready_by_date = response["Ready By Date"];
          self.data.modified_date = response["Modified"];
          self.data.requester_name = response["Requested By"];
          self.data.requester_email = response["Requester Email"];

          this.data.sample_request = {
            id: response["sample_request_id"],
            label: response["Sample Request"],
            url:
              self.$client_url +
              "/#/pos-and-samples/sample-request/view/" +
              response["sample_request_id"]
          };
        })
        .catch(error => {
          console.log(error);

          self.$form.makeToastError(error.message);
        })
        .finally(() => {
          self.isLoading = false;
        });
    },
    async fetchSelectionHistory(id) {
      let self = this;

      return this.$api
        .get(`barrels/${id}/history`)
        .then(response => {
          if (self.$_.isEmpty(response)) return;

          self.selectionTable.items = response;
        })
        .catch(error => {
          console.log(error);

          self.$form.makeToastError(error.message);
        });
    },
    save(_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      let self = this;
      let method = "put";
      let url = `barrels/${self.data.id}`;

      this.isLoading = true;

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false;

          this.$form.makeToastInfo(response.message);

          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;

          let msg = error.message;

          if (!msg) msg = "Error occured. Please reload page!";

          this.$form.makeToastError(msg);

          return Promise.resolve(false);
        });
    },
    updateDateField(e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode);
    },
    updateField(_field, _value, _mode) {
      let payload = {
        id: this.id,
        self: this,
        url: "barrels",
        field: _field,
        value: _value,
        mode: _mode
      };

      this.$form.updateField2(payload);
    },
    updateSelect(id, value) {
      if (!value) {
        value = {
          id: 0,
          label: ""
        };
      }

      this.updateField(id, value);
    },
    async updateSampleRequestSelect(id, value) {
      if (value) {
        this.data.requester_name = value.requester_name;
        this.data.requester_email = value.requester_email;
      }
      if (!value) {
        value = {
          id: 0,
          label: ""
        };
      }

      let payload = {
        id: this.id,
        self: this,
        url: "barrels",
        field: id,
        value: value
      };
      //debugger

      await this.$form.updateField2(payload);

      this.fetchData(this.id);
      this.fetchSelectionHistory(this.id);
    },
    onSampleRequestLinkClicked() {
      this.$router.push({
        name: "Sample request submission",
        params: { action: "view", id: this.data.sample_request.id }
      });
    },
    async reserveBarrel() {
      let self = this;

      let confirm = await this.$form.showConfirmation(
        `Barrel #'${this.id}' will be reserved. Do you want to proceed?`
      );

      if (!confirm) return;

      this.reservationInProgress = true;

      this.$api
        .put(`barrels/${this.id}/reserve`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.fetchData(self.id);
          self.fetchSelectionHistory(this.id);
        })
        .catch(error => {
          self.$form.makeToastError(error.message);
        })
        .finally(() => {
          this.reservationInProgress = false;
        });
    },

    async unreserveBarrel() {
      let self = this;

      let confirm = await this.$form.showConfirmation(
        `Barrel #'${this.id}' will be unreserved. Do you want to proceed?`
      );

      if (!confirm) return;

      this.unreservationInProgress = true;

      this.$api
        .put(`barrels/${this.id}/unreserve`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.fetchData(self.id);
          self.fetchSelectionHistory(this.id);
        })
        .catch(error => {
          self.$form.makeToastError(error.message);
        })
        .finally(() => {
          this.unreservationInProgress = false;
        });
    },
    openBarrelReserveMailModal() {
      //this.newSampleRequestMail.sender = this.profile.data.email1
      this.barrelReserveMail.sender_name = this.profile.data.full_name;
      this.barrelReserveMail.recipient_email = this.data.requester_email;
      this.$refs["barrel-reserve-modal"].show();
    },
    closeBarrelReserveMailModal() {
      this.$refs["barrel-reserve-modal"].hide();
    },
    onBarrelReserveEmailChanged() {
      this.barrelReserveMail.recipient_email = this.$refs[
        "barrel-reserve-recipient"
      ].getValue();
    },

    onBarrelReserveMessageChanged() {
      this.barrelReserveMail.message = this.$refs[
        "barrel-reserve-message"
      ].getValue();
    },

    sendBarrelReserveMail() {
      if (!this.barrelReserveMail.message) {
        this.$form.makeToastError("Message is empty!");

        return;
      }

      this.barrelReserveMail.isLoading = true;

      this.$api
        .post("barrels/new-reservation-mail", this.barrelReserveMail)
        .then(() => {
          this.$form.makeToastInfo("E-mail sent!");

          this.barrelReserveMail.message = "";

          this.closeBarrelReserveMailModal();
        })
        .catch(error => {
          this.$form.makeToastError(error.message);
        })
        .finally(() => {
          this.barrelReserveMail.isLoading = false;
        });
    }
  },
  watch: {}
};
</script>

<style></style>
