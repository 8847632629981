var render = function render(){
  var _vm$controls$barrel_n,
    _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('div', {
    staticClass: "card-body"
  }, [_c('form', [_c('h5', [_vm._v(" Barrel details "), _vm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.barrel_number.id,
      "value": _vm.data.barrel_number,
      "label": (_vm$controls$barrel_n = _vm.controls.barrel_number) === null || _vm$controls$barrel_n === void 0 ? void 0 : _vm$controls$barrel_n.label,
      "readonly": _vm.controls.barrel_number.readonly,
      "mode": _vm.mode,
      "required": false
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.product.id,
      "value": _vm.data.product,
      "label": _vm.controls.product.label,
      "readonly": _vm.controls.product.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.alcohol_percentage.id,
      "value": _vm.data.alcohol_percentage,
      "label": _vm.controls.alcohol_percentage.label,
      "readonly": _vm.controls.alcohol_percentage.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.barrel_size.id,
      "value": _vm.data.barrel_size,
      "label": _vm.controls.barrel_size.label,
      "readonly": _vm.controls.barrel_size.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.date_barelled.id,
      "value": _vm.data.date_barelled,
      "label": _vm.controls.date_barelled.label,
      "readonly": _vm.controls.date_barelled.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.barrel_age.id,
      "value": _vm.data.barrel_age,
      "label": _vm.controls.barrel_age.label,
      "readonly": _vm.controls.barrel_age.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_in_barrel.id,
      "value": _vm.data.amount_in_barrel,
      "label": _vm.controls.amount_in_barrel.label,
      "readonly": _vm.controls.amount_in_barrel.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_remaining.id,
      "value": _vm.data.amount_remaining,
      "label": _vm.controls.amount_remaining.label,
      "readonly": _vm.controls.amount_remaining.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.warehouse.id,
      "value": _vm.data.warehouse,
      "label": _vm.controls.warehouse.label,
      "readonly": _vm.controls.warehouse.readonly,
      "options": _vm.controls.warehouse.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": _vm.controls.warehouse.required
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.reservation_date.id,
      "value": _vm.data.reservation_date,
      "label": _vm.controls.reservation_date.label,
      "readonly": _vm.controls.reservation_date.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.barrel_status.id,
      "value": _vm.data.barrel_status,
      "label": _vm.controls.barrel_status.label,
      "readonly": _vm.controls.barrel_status.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.modified_date.id,
      "value": _vm.data.modified_date,
      "label": _vm.controls.modified_date.label,
      "readonly": _vm.controls.modified_date.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.selection_status.id,
      "value": _vm.data.selection_status,
      "label": _vm.controls.selection_status.label,
      "readonly": _vm.controls.selection_status.readonly,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.selected_by.id,
      "value": _vm.data.selected_by,
      "label": _vm.controls.selected_by.label,
      "readonly": _vm.controls.selected_by.readonly,
      "mode": _vm.mode,
      "required": false
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.ready_by_date.id,
      "value-single": _vm.data.ready_by_date,
      "label": _vm.controls.ready_by_date.label,
      "readonly": _vm.controls.ready_by_date.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.sample_request.id,
      "width": 25,
      "value": _vm.data.sample_request,
      "loading": _vm.controls.sample_request.loading,
      "label": _vm.controls.sample_request.label,
      "readonly": _vm.controls.sample_request.readonly,
      "options": _vm.controls.sample_request.options,
      "allow-empty": true,
      "multiple": false,
      "mode": _vm.mode,
      "link-mode": true,
      "required": false
    },
    on: {
      "link-clicked": _vm.onSampleRequestLinkClicked,
      "changed": _vm.updateSampleRequestSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('inline-input', {
    attrs: {
      "id": _vm.controls.notes.id,
      "value": _vm.data.notes,
      "label": _vm.controls.notes.label,
      "readonly": _vm.controls.notes.readonly,
      "mode": _vm.mode,
      "required": false,
      "is-text-area": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('b-row', [_c('b-col', [_c('h5', [_vm._v("Selection history")]), _c('b-table', {
    attrs: {
      "fields": _vm.selectionTable.columns,
      "items": _vm.selectionTable.items,
      "bg-variant": "ligth"
    }
  })], 1)], 1)], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      },
      "custom-buttons": [{
        text: 'Notification',
        icon: 'envelope',
        loading: false,
        visible: (_vm.profile.data.role === 'H2' || _vm.profile.data.full_name === 'Hoffman Mike') && _vm.data.barrel_status === 'Reserved',
        method: 'openBarrelReserveMailModal',
        variant: 'warning'
      }, {
        text: 'Reserve',
        icon: 'registered',
        loading: _vm.reservationInProgress,
        visible: (_vm.profile.data.role === 'H2' || _vm.profile.data.full_name === 'Hoffman Mike') && _vm.data.amount_remaining > 0 && _vm.data.barrel_status === 'Full',
        method: 'reserveBarrel',
        variant: 'primary'
      }, {
        text: 'Unreserve',
        icon: 'registered',
        loading: _vm.unreservationInProgress,
        visible: (_vm.profile.data.role === 'H2' || _vm.profile.data.full_name === 'Hoffman Mike') && _vm.data.barrel_status === 'Reserved' && _vm.data.selection_status === 'Available',
        method: 'unreserveBarrel',
        variant: 'danger'
      }]
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Barrels'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Barrel submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Barrel submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      },
      "custom-click": function customClick(name) {
        _this[name]();
      }
    }
  })], 1)]), _c('b-modal', {
    ref: "barrel-reserve-modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "md",
      "title": "Barrel reserve notification"
    }
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": "barrel-reserve-sender",
      "value": _vm.barrelReserveMail.sender_name,
      "hide-label": false,
      "label": "Sender",
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": true,
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6"
    }
  }, [_c('inline-input', {
    ref: "barrel-reserve-recipient",
    attrs: {
      "id": "barrel-reserve-recipient",
      "value": _vm.barrelReserveMail.recipient_email,
      "hide-label": false,
      "label": "Recipient",
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": true
    },
    on: {
      "changed": _vm.onBarrelReserveEmailChanged
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12",
      "md": "12"
    }
  }, [_c('inline-input', {
    ref: "barrel-reserve-message",
    attrs: {
      "id": "barrel-reserve-message",
      "value": _vm.barrelReserveMail.message,
      "hide-label": false,
      "label": "Message",
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": true,
      "is-text-area": true
    },
    on: {
      "changed": _vm.onBarrelReserveMessageChanged
    }
  })], 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark",
      "disabled": _vm.barrelReserveMail.isLoading
    },
    on: {
      "click": function click($event) {
        return _vm.sendBarrelReserveMail();
      }
    }
  }, [_vm.barrelReserveMail.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e(), !_vm.barrelReserveMail.isLoading ? _c('font-awesome-icon', {
    attrs: {
      "icon": "paper-plane"
    }
  }) : _vm._e(), _vm._v(" Send mail ")], 1), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeBarrelReserveMailModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }